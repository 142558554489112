import { Trans, useTranslation } from 'react-i18next'
import { FCC } from '~/core/@types/global'
import { TypographyText } from '~/core/ui/Text'
import { useClient } from '~/lib/hooks/use-is-client'
import {
  AdvancedFeature,
  ImageJPGBackground,
  ImagePNGIllustration
} from './components/AuthComponent'

const LayoutLoginRegister: FCC = ({ children }) => {
  const { t } = useTranslation()
  const { isClient } = useClient()

  return (
    <div className="grid overflow-hidden tablet:min-h-screen tablet:grid-cols-2">
      <div className="h-full">{children}</div>
      <div className="relative h-[870px] overflow-hidden bg-[#EBECFF] tablet:h-full">
        <ImageJPGBackground
          largeDesktopImageName="background_largeDesktop"
          desktopImageName="background_largeDesktop"
          tabletImageName="background_tablet"
          mobileImageName="background_mobile"
        />
        <ImagePNGIllustration
          desktopImageName="illustration"
          tabletImageName="illustration_tablet"
          mobileImageName="illustration_mobile"
        />

        {isClient ? (
          <div className="absolute z-10 px-4 pt-8 tablet:left-6 tablet:pl-0 tablet:pr-6 tablet:pt-10 desktop:left-16 desktop:w-[501px] desktop:px-0 desktop:pt-16 largeDesktop:left-[145px]">
            <TypographyText className="mb-6 text-2xl font-semibold text-gray-900 tablet:mb-8 tablet:text-3xl desktop:text-4xl">
              <Trans i18nKey={'auth:transformTheWayYouHire'}>
                <span className="text-primary-400" />
              </Trans>
            </TypographyText>
            <ul className="">
              <AdvancedFeature
                index={0}
                content={`${t('auth:seamlessHiringFlow')}`}
              />
              <AdvancedFeature
                index={1}
                content={`${t('auth:advancedTalentSearch')}`}
              />
              <AdvancedFeature
                index={2}
                content={`${t('auth:unifiedRecruitmentPlatform')}`}
              />
              <AdvancedFeature
                index={3}
                content={`${t('auth:hiringPerformanceDashboard')}`}
              />
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default LayoutLoginRegister
