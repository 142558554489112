import { signIn } from 'next-auth/react'
import { Trans, useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import configuration from '~/configuration'
import { IFormAction } from '~/core/@types/global'
import { Button } from '~/core/ui/Button'
import { Container } from '~/core/ui/Container'
import { DynamicImportForm } from '~/core/ui/DynamicImportForm'
import { FormControlItem } from '~/core/ui/FormControlItem'
import { TypographyH2 } from '~/core/ui/Heading'
import { Input } from '~/core/ui/Input'
import { SocialButton } from '~/core/ui/SocialButton'
import { TypographyText } from '~/core/ui/Text'
import { currentTimeZone } from '~/core/utilities/common'
import schemaLoginForm from '~/lib/features/login/schema/login-form'
import { ILoginForm } from '~/lib/features/login/types'
import { useTrackingUTM } from '~/lib/hooks/use-tracking-utm'
import DropdownLanguage from '../DropdownLanguage'

const LoginView: React.FC<{
  providers: object
  onFinish: (data: ILoginForm, formAction: IFormAction) => Promise<void>
  loginSSO: () => void
  isLoadingEmail: boolean
  isLoadingSSO: boolean
}> = ({ providers, onFinish, isLoadingEmail, loginSSO, isLoadingSSO }) => {
  const { t } = useTranslation()
  const { url, isHasParam } = useTrackingUTM()
  const [isLoadingSocial, setLoadingSocial] = useState<any>()

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="mb-12 flex items-center justify-center space-x-1 pt-6 text-center tablet:mb-0">
        <TypographyText className="text-base text-gray-700 dark:text-gray-300">
          <Trans i18nKey={'auth:loginHaveAccount'} />
        </TypographyText>
        <Link href={configuration.path.register}>
          <TypographyText className="text-base font-medium text-primary-400 hover:underline dark:text-primary-400">
            <Trans i18nKey={'auth:loginHaveAccountButton'} />
          </TypographyText>
        </Link>
      </div>

      <Container overrideClass="mx-0 w-full tablet:max-w-[448px] tablet:mx-auto px-6 desktop:px-0">
        <div className=" flex flex-col">
          <div className="flex shrink-0 grow flex-col items-center justify-center">
            <div className="flex w-full flex-col items-center">
              {configuration.production === false ? (
                <Image
                  width={64}
                  height={64}
                  alt="logo"
                  src="/img/logo/logo-primary.png"
                />
              ) : (
                <a
                  href="https://hireforce.io/?utm_campaign=HIREFORCE&utm_source=app-hireforce&utm_medium=logo&utm_content=landing-explore"
                  target="_blank">
                  <Image
                    width={64}
                    height={64}
                    alt="logo"
                    src="/img/logo/logo-primary.png"
                  />
                </a>
              )}

              <div className="my-8 text-center desktop:my-10">
                <TypographyH2 className="text-center text-2xl text-gray-900 dark:text-gray-300 desktop:text-3xl">
                  <Trans i18nKey={'auth:loginWelcome'} />
                </TypographyH2>
                <div className="mt-2 desktop:mt-3">
                  <TypographyText className="text-base text-gray-700 dark:text-gray-300">
                    <Trans i18nKey={'auth:loginWelcomeDescription'} />
                  </TypographyText>
                </div>
              </div>

              <DynamicImportForm
                id="login-form"
                className="mb-4 w-full border-b border-gray-100 pb-4 tablet:mb-6 tablet:pb-6 desktop:px-2"
                schema={schemaLoginForm(t)}
                onSubmit={onFinish}
                defaultValue={{}}
                noUseSubmit>
                {({ formState, control }) => {
                  return (
                    <>
                      <div className="mb-4">
                        <Controller
                          control={control}
                          name="email"
                          defaultValue=""
                          render={({ field: { onChange, value } }) => (
                            <FormControlItem
                              destructive={
                                formState.errors && !!formState.errors.email
                              }
                              destructiveText={
                                formState.errors &&
                                (formState.errors.email?.message as string)
                              }
                              labelRequired
                              label={`${t('auth:loginFormLabelEmail')}`}>
                              <Input
                                onChange={onChange}
                                value={value}
                                destructive={
                                  formState.errors && !!formState.errors.email
                                }
                              />
                            </FormControlItem>
                          )}
                        />
                      </div>

                      <Button
                        isDisabled={isLoadingEmail}
                        isLoading={isLoadingEmail}
                        className="w-full"
                        htmlType="submit"
                        label={`${t('auth:loginButtonSubmitWithEmail')}`}
                      />
                    </>
                  )
                }}
              </DynamicImportForm>

              {providers && Object.keys(providers).length ? (
                <div className="w-full desktop:px-2">
                  {Object.values(providers).map((provider) => (
                    <div key={provider.name} className="mt-2 flex-1 first:mt-0">
                      <SocialButton
                        isLoading={isLoadingSocial?.[provider.id]}
                        className="w-full"
                        onClick={async () => {
                          await setLoadingSocial({
                            [provider.id]: true
                          })
                          return signIn(
                            provider.id,
                            {
                              callbackUrl: isHasParam ? url : undefined
                            },
                            { timeZone: currentTimeZone }
                          )
                        }}
                        size="lg"
                        type={provider.id}
                        label={`${t('auth:loginSocialWith')} ${
                          provider.id === 'azure-ad'
                            ? 'Microsoft'
                            : provider.name
                        }`}
                      />
                    </div>
                  ))}
                </div>
              ) : null}

              <div className="mt-2 w-full desktop:px-2">
                <div className="mt-2 flex-1 first:mt-0">
                  <SocialButton
                    isLoading={isLoadingSSO}
                    className="w-full"
                    onClick={loginSSO}
                    size="lg"
                    type="sso"
                    label={`${t('auth:loginSocialWith')} SSO`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="mb-4 mt-[94px] flex w-full items-center justify-center space-x-8 tablet:mt-4">
        <div>
          <a
            href={`mailto:${configuration.mailto}`}
            className="hover:underline">
            <TypographyText className="text-center text-sm font-medium text-gray-700">
              <Trans i18nKey="auth:loginTroubleContact" />
            </TypographyText>
          </a>
        </div>
        <DropdownLanguage />
      </div>
    </div>
  )
}

export default LoginView
