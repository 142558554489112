import { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next'
import { useTranslation } from 'react-i18next'
import GoogleTagManagementPublic from '~/components/GoogleTagManagementPublic'
import HeadMetaTags from '~/components/HeadMetaTags'
import { PUBLIC_APP_NAME } from '~/core/constants/env'
import LoginContainer from '~/features/login'
import { withAuthProps } from '~/lib/next/with-auth'
import { withAuthPropsRoutingHandler } from '~/lib/next/with-auth-handler-hoc'

function LoginPage({
  providers
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const { t } = useTranslation()
  return (
    <>
      <HeadMetaTags title={`${t(`common:seo:login`, { PUBLIC_APP_NAME })}`} />

      <GoogleTagManagementPublic />
      <LoginContainer providers={providers} />
    </>
  )
}

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  return await withAuthProps(ctx)
}

export default withAuthPropsRoutingHandler(LoginPage)
