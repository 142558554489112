import { FC } from 'react'
import { TypographyText } from '~/core/ui/Text'
import { cn } from '~/core/ui/utils'

export const ImageJPGBackground: FC<{
  largeDesktopImageName?: string
  desktopImageName?: string
  tabletImageName?: string
  mobileImageName?: string
}> = ({
  largeDesktopImageName,
  desktopImageName,
  tabletImageName,
  mobileImageName
}) => {
  return (
    <>
      <img
        width={960}
        height={1080}
        src={`img/feature/register/${largeDesktopImageName}.jpg`}
        srcSet={`img/feature/register/${largeDesktopImageName}_2x.jpg 2x, img/feature/register/${largeDesktopImageName}.jpg 1x`}
        alt="login and register background"
        className="absolute left-0 top-0 hidden max-w-none object-cover largeDesktop:block"
      />
      <img
        width={720}
        height={768}
        src={`img/feature/register/${desktopImageName}.jpg`}
        srcSet={`img/feature/register/${desktopImageName}_2x.jpg 2x, img/feature/register/${desktopImageName}.jpg 1x`}
        alt="login and register background"
        className="absolute left-0 top-0 hidden max-w-none object-cover desktop:block largeDesktop:hidden"
      />
      <img
        width={720}
        height={1024}
        src={`img/feature/register/${tabletImageName}.jpg`}
        srcSet={`img/feature/register/${tabletImageName}_2x.jpg 2x, img/feature/register/${tabletImageName}.jpg 1x`}
        alt="login and register background"
        className="absolute left-0 top-0 hidden max-w-none object-cover tablet:block desktop:hidden"
      />
      <img
        width={693}
        height={870}
        src={`img/feature/register/${mobileImageName}.jpg`}
        srcSet={`img/feature/register/${mobileImageName}_2x.jpg 2x, img/feature/register/${mobileImageName}.jpg 1x`}
        alt="login and register background"
        className="absolute left-0 top-0 max-w-none tablet:hidden"
      />
    </>
  )
}

export const ImagePNGIllustration: FC<{
  desktopImageName?: string
  tabletImageName?: string
  mobileImageName?: string
}> = ({ desktopImageName, tabletImageName, mobileImageName }) => {
  return (
    <>
      <img
        width={847}
        height={717}
        src={`img/feature/register/${desktopImageName}.png`}
        srcSet={`img/feature/register/${desktopImageName}_2x.png 2x, img/feature/register/${desktopImageName}.png 1x`}
        alt="login and register illustration"
        className="absolute hidden h-[717px] w-[847px] max-w-[847px] desktop:-left-6 desktop:top-[192px] desktop:block largeDesktop:left-[57px]"
      />
      <img
        width={823}
        height={909}
        src={`img/feature/register/${tabletImageName}.png`}
        srcSet={`img/feature/register/${tabletImageName}_2x.png 2x, img/feature/register/${tabletImageName}.png 1x`}
        alt="login and register illustration"
        className="absolute hidden max-w-none tablet:block desktop:hidden"
      />
      <img
        width={764}
        height={837}
        src={`img/feature/register/${mobileImageName}.png`}
        srcSet={`img/feature/register/${mobileImageName}_2x.png 2x, img/feature/register/${mobileImageName}.png 1x`}
        alt="login and register illustration"
        className="absolute top-[45px] max-w-none tablet:hidden"
      />
    </>
  )
}

const CheckIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className={className}>
    <circle cx="9.5" cy="10.5" r="5.5" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM13.7071 8.70711C14.0976 8.31658 14.0976 7.68342 13.7071 7.29289C13.3166 6.90237 12.6834 6.90237 12.2929 7.29289L9 10.5858L7.70711 9.29289C7.31658 8.90237 6.68342 8.90237 6.29289 9.29289C5.90237 9.68342 5.90237 10.3166 6.29289 10.7071L8.29289 12.7071C8.68342 13.0976 9.31658 13.0976 9.70711 12.7071L13.7071 8.70711Z"
      fill="#5CBB90"
    />
  </svg>
)

export const AdvancedFeature: FC<{ index?: number; content?: string }> = ({
  index = 0,
  content
}) => {
  const marginLeftClassName = [
    'ml-0',
    'ml-4 tablet:ml-8 desktop:ml-12',
    'ml-8 tablet:ml-16 desktop:ml-24',
    'ml-12 tablet:ml-24 desktop:ml-[144px]'
  ]
  return (
    <li
      className={cn('mt-3 first:mt-0 tablet:mt-4', marginLeftClassName[index])}>
      <div className="inline-flex items-center space-x-3 rounded-2xl bg-gradient-to-r from-white/30 to-white/70 px-2.5 py-1 first:mt-0">
        <CheckIcon className="flex-none" />
        <TypographyText className="text-base text-gray-700">
          {content}
        </TypographyText>
      </div>
    </li>
  )
}
