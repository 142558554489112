import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export function useTrackingUTM() {
  const router = useRouter()
  const [paramState, setParamState] = useState<{
    isHasParam: boolean
    utm_campaign?: string
    utm_source?: string
    utm_medium?: string
    utm_content?: string
    url?: string
  }>({
    isHasParam: false,
    utm_campaign: undefined,
    utm_source: undefined,
    utm_medium: undefined,
    utm_content: undefined,
    url: undefined
  })

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const utmCampaign = urlParams.get('utm_campaign') || ''
    const utmSource = urlParams.get('utm_source') || ''
    const utmMedium = urlParams.get('utm_medium') || ''
    const utmContent = urlParams.get('utm_content') || ''

    if (utmCampaign || utmSource || utmMedium || utmContent) {
      setParamState({
        isHasParam: true,
        utm_campaign: utmCampaign,
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_content: utmContent,
        url: `${window.location.pathname}?utm_campaign=${utmCampaign}&utm_source=${utmSource}&utm_medium=${utmMedium}&utm_content=${utmContent}`
      })
    }
  }, [router])

  return paramState
}
