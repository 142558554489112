import { useRouter } from 'next/router'
import { destroyCookie, parseCookies } from 'nookies'
import { useCallback, useEffect, useMemo } from 'react'
import LayoutHybrid from '~/components/Layout/LayoutHybrid'
import LayoutLoginRegister from '~/components/Layout/LayoutLoginRegister'
import LoginView from '~/components/Login'
import configuration from '~/configuration'
import { IFormAction } from '~/core/@types/global'
import { COOKIE_PATH, SESSION_COOKIE_SSO } from '~/core/constants/cookies'
import { useRecaptcha, verifyRecaptcha } from '~/core/hooks/use-verify-captcha'
import { setSessionCookieClient } from '~/core/middleware/save-session-cookie'
import useMiddlewareRequest from '~/core/middleware/use-middleware-request'
import AppRouteLoading from '~/core/ui/AppRouteLoading'
import { catchErrorFromRequest } from '~/core/utilities/catch-api-error'
import { ILoginForm } from '~/lib/features/login/types'
import { useTrackingUTM } from '~/lib/hooks/use-tracking-utm'
import useBoundStore from '~/lib/store'

const LoginContainer: React.FC<{
  providers: object
}> = ({ providers }) => {
  useRecaptcha()
  const router = useRouter()
  const { utm_campaign, utm_source, utm_medium, utm_content, isHasParam } =
    useTrackingUTM()
  const cookies = parseCookies()
  const { setToast } = useBoundStore()

  const { trigger: loginWithEmail, isMutating: isLoading } =
    useMiddlewareRequest({
      endpoint: configuration.api.login,
      method: 'POST'
    })

  const ssoCookie = cookies[SESSION_COOKIE_SSO]
  const ssoObj = useMemo(
    () => (ssoCookie ? JSON.parse(ssoCookie) : {}),
    [ssoCookie]
  )

  useEffect(() => {
    const options = { path: COOKIE_PATH }
    destroyCookie(null, SESSION_COOKIE_SSO, options)
  }, [])

  const { trigger: loginWithSSO, isMutating: isLoadingSSO } =
    useMiddlewareRequest({
      endpoint: configuration.api.loginSSO,
      method: 'POST'
    })

  const navigateToVerifyEmailPage = useCallback(
    (data: ILoginForm) => {
      void router.push(`${configuration.path.verifyEmail}?email=${data.email}`)
    },
    [router]
  )

  const loginWithEmailCallback = useCallback(
    async (data: ILoginForm, formAction: IFormAction) => {
      if (isLoading) {
        return
      }

      verifyRecaptcha(async (isNotGoogleBOT: boolean) => {
        if (isNotGoogleBOT) {
          try {
            await loginWithEmail({
              ...data,
              ...(isHasParam
                ? { utm_campaign, utm_source, utm_medium, utm_content }
                : {}),
              provider: 'web'
            })
            navigateToVerifyEmailPage(data)
          } catch (error) {
            catchErrorFromRequest({
              error,
              formAction,
              callbackHandleStatusError422: (keys) => {
                keys.forEach((session: { field: string; message: string }) => {
                  formAction.setError('email', {
                    type: 'custom',
                    message: [session.message]
                  })
                })
              }
            })
          }
        }
      })
    },
    [
      isLoading,
      loginWithEmail,
      isHasParam,
      utm_campaign,
      utm_source,
      utm_medium,
      utm_content,
      navigateToVerifyEmailPage
    ]
  )

  const loginSSOCallback = useCallback(async () => {
    if (isLoadingSSO) {
      return
    }

    verifyRecaptcha(async (isNotGoogleBOT: boolean) => {
      if (isNotGoogleBOT) {
        try {
          const result = await loginWithSSO({})

          const obj = {
            ...ssoObj,
            ssoToken: result.ssoToken
          }
          await setSessionCookieClient(
            SESSION_COOKIE_SSO,
            JSON.stringify(obj).toString(),
            30 * 24 * 60 * 60
          )

          void router.push(configuration.path.loginSSO)
        } catch (error) {
          catchErrorFromRequest({ error, setToast })
        }
      }
    })
  }, [isLoadingSSO, loginWithSSO, ssoObj, router, setToast])

  return (
    <LayoutHybrid>
      <AppRouteLoading>
        <LayoutLoginRegister>
          <LoginView
            isLoadingEmail={isLoading}
            isLoadingSSO={isLoadingSSO}
            providers={providers}
            onFinish={loginWithEmailCallback}
            loginSSO={loginSSOCallback}
          />
        </LayoutLoginRegister>
      </AppRouteLoading>
    </LayoutHybrid>
  )
}

export default LoginContainer
